<template>
  <div id="chartPie" class="pie-wrap"></div>
</template>
<script>
import echarts from "echarts";
require("echarts/theme/macarons"); // echarts theme
export default {
  name: "PieEcharts",
  props: {
    id: String,
    ctitle: String,
    chartdata: Array,
    stype: {
      type: String,
      default: "line",
    },
    zbshow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      chartLine: null,
      chartdataList: [],
    };
  },
  watch: {
    chartdata: {
      handler(val) {
        this.chartdataList = val;
        this.drawLineChart();
      },
      deep: true,
    },
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.drawLineChart();
    // });
  },
  methods: {
    drawLineChart() {
      const { chartdataList } = this;
      let chartId = document.getElementById("chartPie");
      this.chartLine = echarts.init(chartId); // 基于准备好的dom，初始化echarts实例
      let option = {
        title: {
          text: "交易种类",
          textStyle: {
            color: "#333",
            fontSize: "22",
            fontWeight: "100",
          },
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          right: "10",
          top: "center",
          data: chartdataList.map((x) => {
            return x.name;
          }),
          textStyle: {
            color: "#333",
          },
        },
        series: [
          {
            name: "菜品种类",
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "22",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: chartdataList.map((x) => {
              return {
                value: x.quantity,
                name: x.name,
              };
            }),
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表
      this.chartLine.setOption(option);
    },
  },
};
</script>

<style scoped>
.pie-wrap {
  width: 100%;
  height: 300px;
}
</style>
