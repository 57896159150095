<template>
  <div class="dashboard-editor-container">
    <panel-group :dpData="BigScreenData.dpData" />
    <el-row style="background: #fff; padding: 16px 16px 0; margin-bottom: 32px">
      <line-chart :echartData="BigScreenData.lineData" />
    </el-row>
    <el-row :gutter="32">
      <el-col :xs="24" :sm="24" :lg="12">
        <div class="chart-wrapper">
          <pie-chart :chartdata="BigScreenData.pieData" />
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :lg="12">
        <div class="chart-wrapper">
          <bar-chart />
        </div>
      </el-col>
    </el-row>
  </div>
</template>
 
<script>
import PanelGroup from "./components-test/PanelGroup";
import LineChart from "./components-test/LineChart";
import PieChart from "./components-test/PieChart";
import BarChart from "./components-test/BarChart";

const lineChartData = {
  newVisitis: {
    expectedData: [100, 120, 161, 134, 105, 160, 165],
    actualData: [120, 82, 91, 154, 162, 140, 145],
  },
  messages: {
    expectedData: [200, 192, 120, 144, 160, 130, 140],
    actualData: [180, 160, 151, 106, 145, 150, 130],
  },
  purchases: {
    expectedData: [80, 100, 121, 104, 105, 90, 100],
    actualData: [120, 90, 100, 138, 142, 130, 130],
  },
  shoppings: {
    expectedData: [130, 140, 141, 142, 145, 150, 160],
    actualData: [120, 82, 91, 154, 162, 140, 130],
  },
};
export default {
  name: "DashboardAdmin",
  components: {
    PanelGroup,
    LineChart,
    PieChart,
    BarChart,
  },
  data() {
    return {
      lineChartData: lineChartData.newVisitis,
      BigScreenData: {
        dpData: {},
        lineData: [],
        pieData: [],
      },
    };
  },
  mounted() {
    this.$socket.emit("connect", 1);
  },
  methods: {
    handleSetLineChartData(type) {
      this.lineChartData = lineChartData[type];
    },
  },
  sockets: {
    //这里是监听connect事件
    connect() {
      console.log("链接connect----", 111);
    },
    // 接收数据 函数
    push_event(data) {
      let socketsData = JSON.parse(data.content);
      const { dataType } = socketsData;
      let list;
      switch (dataType) {
        case "BigScreen-01":
          console.log("BigScreen--01------------", socketsData);
          this.BigScreenData.dpData = socketsData.content;
          break;
        case "BigScreen-02":
          console.log("BigScreen--02------------", socketsData);
          list = [
            {
              name: "昨日",
              data: socketsData.content.yesterday.map((x) => {
                return x.total;
              }),
              time: socketsData.content.yesterday.map((x) => {
                return x.time;
              }),
            },
            {
              name: "今日",
              data: socketsData.content.thatDay.map((x) => {
                return x.total;
              }),
              time: socketsData.content.thatDay.map((x) => {
                return x.time;
              }),
            },
          ];
          this.BigScreenData.lineData = list;
          break;
        case "BigScreen-03":
          console.log("BigScreen--03------------", socketsData);
          this.BigScreenData.pieData = socketsData.content;
          break;
      }
    },
    // 监听重新链接，函数
    reconnect(data) {
      console.log("重新链接", data);
      this.$socket.emit("connect", 1);
    },
    // 监听断开连接，函数
    disconnect() {
      console.log("socket已断开链接");
    },
  },
};
</script>
<style lang="scss" scoped>
.dashboard-editor-container {
  padding: 32px;
  background-color: rgb(240, 242, 245);
  position: relative;

  .github-corner {
    position: absolute;
    top: 0px;
    border: 0;
    right: 0;
  }

  .chart-wrapper {
    background: #fff;
    padding: 16px 16px 0;
    margin-bottom: 32px;
  }
}

@media (max-width: 1024px) {
  .chart-wrapper {
    padding: 8px;
  }
}
</style>