<template>
  <el-row :gutter="40" class="panel-group">
    <template v-for="(item, index) in tabList">
      <el-col :key="index" :xs="12" :sm="12" :lg="6" class="card-panel-col">
        <div class="card-panel">
          <!-- @click="handleSetLineChartData('newVisitis')" -->
          <div
            class="card-panel-icon-wrapper"
            :class="[`icon-${item.className}`]"
          >
            <svg-icon
              :icon-class="item.className"
              class-name="card-panel-icon"
            />
          </div>
          <div class="card-panel-description">
            <div class="card-panel-text">{{ item.title }}</div>
            <count-to
              :start-val="item.oldData"
              :end-val="item.data"
              :duration="2600"
              class="card-panel-num"
            />
          </div>
        </div>
      </el-col>
    </template>
  </el-row>
</template>

<script>
import CountTo from 'vue-count-to';

export default {
  props: {
    dpData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: {
    CountTo
  },
  data() {
    return {
      tabList: [
        {
          title: '客流总量',
          data: 0,
          oldData: 0,
          className: 'peoples'
        },
        {
          title: '交易笔数',
          data: 0,
          oldData: 0,
          className: 'shopping'
        },
        {
          title: '交易金额',
          data: 0,
          oldData: 0,
          className: 'money'
        },
        {
          title: '客流总量',
          data: 0,
          oldData: 0,
          className: 'order'
        }
      ]
    };
  },
  watch: {
    dpData: {
      handler(val) {
        if (!this.$test.isEmpty(val)) {
          this.tabList = [
            {
              title: '客流总量',
              data: parseFloat(val.passengerFlow),
              oldData: this.tabList.length > 0 ? this.tabList[0].data : 0,
              className: 'peoples'
            },
            {
              title: '交易总重',
              data: parseInt(val.weight),
              oldData: this.tabList.length > 0 ? this.tabList[1].data : 0,
              className: 'shopping'
            },
            {
              title: '交易金额',
              data: parseFloat(val.total),
              ldData: this.tabList.length > 0 ? this.tabList[2].data : 0,
              className: 'money'
            },
            {
              title: '交易笔数',
              data: parseFloat(val.quantity),
              ldData: this.tabList.length > 0 ? this.tabList[3].data : 0,
              className: 'order'
            }
          ];
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    handleSetLineChartData(type) {
      this.$emit('handleSetLineChartData', type);
    }
  }
};
</script>

<style lang="scss" scoped>
.panel-group {
  margin-top: 18px;

  .card-panel-col {
    margin-bottom: 32px;
  }

  .card-panel {
    height: 108px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    color: #666;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05);
    border-color: rgba(0, 0, 0, 0.05);

    &:hover {
      .card-panel-icon-wrapper {
        color: #fff;
      }

      .icon-peoples {
        background: #40c9c6;
      }

      .icon-shopping {
        background: #36a3f7;
      }

      .icon-money {
        background: #f4516c;
      }

      .icon-order {
        background: #34bfa3;
      }
    }

    .icon-peoples {
      color: #40c9c6;
    }

    .icon-shopping {
      color: #36a3f7;
    }

    .icon-money {
      color: #f4516c;
    }

    .icon-order {
      color: #34bfa3;
    }

    .card-panel-icon-wrapper {
      float: left;
      margin: 14px 0 0 14px;
      padding: 16px;
      transition: all 0.38s ease-out;
      border-radius: 6px;
    }

    .card-panel-icon {
      float: left;
      font-size: 48px;
    }

    .card-panel-description {
      float: right;
      font-weight: bold;
      margin: 26px;
      margin-left: 0px;

      .card-panel-text {
        line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        margin-bottom: 12px;
      }

      .card-panel-num {
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 550px) {
  .card-panel-description {
    display: none;
  }

  .card-panel-icon-wrapper {
    float: none !important;
    width: 100%;
    height: 100%;
    margin: 0 !important;

    .svg-icon {
      display: block;
      margin: 14px auto !important;
      float: none !important;
    }
  }
}
</style>