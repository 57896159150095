<template>
  <div id="chartLine" class="line-wrap"></div>
</template>
<script>
import echarts from 'echarts';
require('echarts/theme/macarons'); // echarts theme
export default {
  name: 'EChart',
  props: {
    id: String,
    ctitle: String,
    stype: {
      type: String,
      default: 'line'
    },
    zbshow: {
      type: Boolean,
      default: true
    },
    echartData: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      chartLine: null,
      echartListData: []
    };
  },
  watch: {
    echartData(val) {
      this.echartListData = val;
      this.drawLineChart();
    }
  },
  mounted() {
    // this.$nextTick(() => {
    // this.drawLineChart();
    // });
  },
  created() {
    // this.drawLineChart();
  },
  methods: {
    drawLineChart() {
      const { echartListData } = this;
      let chartId = document.getElementById('chartLine');
      this.chartLine = echarts.init(chartId); // 基于准备好的dom，初始化echarts实例
      let option = {
        title: {
          text: '24小时交易趋势对比',
          textStyle: {
            color: '#333',
            fontSize: '22'
          }
        },
        color: ['#81E430', '#C23531'],
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          icon: 'rect',
          textStyle: {
            color: '#333'
          },
          data: ['昨日', '今日']
        },

        grid: {
          left: '0',
          right: '2%',
          bottom: '0',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          data: echartListData[0].time.map(x => {
            return x;
          }),
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: '#333'
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: '#333'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#45495e'
            }
          }
        },
        itemStyle: {
          borderWidth: 4
        },

        series: echartListData.map(x => {
          return {
            name: x.name,
            data: x.data,
            type: 'line',
            smooth: true,
            lineStyle: {
              width: 5
            }
          };
        })
      };

      // 使用刚指定的配置项和数据显示图表
      this.chartLine.setOption(option);
    }
  }
};
</script>

<style scoped>
.line-wrap {
  width: 100%;
  height: 380px;
}
</style>
